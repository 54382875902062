import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import Select from "react-select";
import SpecialistBriefingModal from "../modals/SpecialistBriefingModal";

class UserBriefingsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelected: null,
      userData: getUserData(),
      purchases: [],
      usersAvailable: [],
      isOpenModalBriefing: false,
      purchaseUserId: null,
    }
  }

  async componentDidMount() {
    await this.getUsers()
    await this.getPurchases()
  }

  async getPurchases() {
    let routeParams = ''
    if (this.state.userSelected && this.state.userSelected.value) {
      routeParams = `?user_id=${this.state.userSelected.value}`
    }

    const response = await apiRequest(`/purchase-users${routeParams}`, {method: 'GET'})
    this.setState({purchases: response.data.data})
  }

  async getUsers() {
    await apiRequest("/user-with-purchases", {method: 'GET'})
      .then(response => {
        const usersAvailable = []
        response.data.data.map(user => {
          usersAvailable.push({
            value: user.id,
            label: `${user.name} - ${user.email}`,
          });
        });
        this.setState({usersAvailable: usersAvailable});
      });
  }

  changeUser(user) {
    this.setState({userSelected: user}, this.getPurchases)
  }

  openBriefingModal(info) {
    this.setState({
      isOpenModalBriefing: true,
      purchaseUserId: info.purchase_user_id,
    })
  }

  saveBriefing() {
    this.closeBriefingModal()
  }

  closeBriefingModal() {
    this.setState({
      isOpenModalBriefing: false,
      purchaseUserId: null,
    }, this.getPurchases)

  }

  render() {

    return (
      <>
        <SpecialistBriefingModal
          open={this.state.isOpenModalBriefing}
          purchaseUserId={this.state.purchaseUserId}
          onClose={() => this.closeBriefingModal()}
          onSave={(data) => this.saveBriefing(data)}
        />

        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{window.gettext('Briefings dos Usuários')}</CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          {this.renderUserFilters()}
          {this.renderTablePurchases()}
        </div>
      </>
    );
  }

  renderUserFilters() {
    return <>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h6">{window.gettext('Pesquisar Usuários')}</CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="align-items-center">
                <Col md='9'>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="userSelected"
                    placeholder={window.gettext("Selecione um usuário")}
                    closeMenuOnSelect={true}
                    value={this.state.userSelected}
                    onChange={value => {
                      this.changeUser(value);
                    }}
                    options={[
                      {
                        value: null,
                        label: "Selecione...",
                      },
                      ...this.state.usersAvailable
                    ]}
                  />
                </Col>
                <Col md="2">
                  <Button color="outline-primary" type="button" onClick={() => this.getPurchases()}>
                    Filtrar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  }

  renderTablePurchases() {
    return <>
      <Row>
        <Col md="12">
          <Card>
            <small className={'text-right'}>* listando últimos 50 registros</small>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{window.gettext('Usuário')}</th>
                  <th>{window.gettext('Produto')}</th>
                  <th>{window.gettext('Data da Compra')}</th>
                  <th>{window.gettext('Ação')}</th>
                </tr>
              </thead>
              <tbody>
              {this.state.purchases.map((info, index) => {
                return this.renderRowPurchases(info, index)
              })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  }
  renderRowPurchases(info, index) {
    return <tr key={index}>
      <td>{info.user.id}</td>
      <td>{info.user.name}</td>
      <td>{info.purchase.product.name}</td>
      <td>{info.created_at}</td>
      <td>
        <Button color="primary" size="sm" onClick={() => this.openBriefingModal(info)}>
          {window.gettext('Briefing')}
          <span className="ml-1 btn-label">
            {info.has_briefing ? (
              <i className="nc-icon nc-check-2"/>
            ) : (
              <i className="nc-icon nc-simple-remove"/>
            )}
          </span>
        </Button>
      </td>
    </tr>
  }
}

export default withNotify(UserBriefingsList);
