import React from "react";
import AsyncSelect from "react-select/lib/Async";


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label, Nav, NavItem, NavLink,
    Row, TabContent, TabPane
} from "reactstrap";

import apiRequest from "../../../lib/Api";
import {getUserData} from "../../../lib/Auth";
import withNotify from "../../../lib/NotificationWrapper";
import Select from "react-select";

class PurchaseUsers extends React.Component {
    constructor(props) {
        let userData = getUserData();
        super(props);
        this.state = {
            horizontalTabs: 'associar',
            purchase: null,
            organizationId: userData.role !== 'super_admin' ? userData.organization.id : null,
            purchases: [],
            users: [],
            usersDesassociar: [],
            allPurchasesUser: [],
            purchaseUser: [],
            enabledUsers: [],
            loading: false,
            userData: userData,
            working: false,
            errors: {},
            user: null,
            purchasesByUsers: [],
        }
    }

    get role() {
        return this.state.userData.role;
    }

    componentDidMount() {
        this.loadPurchases();
        if (this.role !== 'super_admin') {
            this.loadUsers();
        }
        // otherwise, super admin must select the purchase first
    }

    clearForm() {
        this.setState({
            purchase: null,
            organizationId: this.state.userData.role !== 'super_admin' ? this.state.userData.organization.id : null,
            purchases: [],
            enabledUsers: [],
            purchasesByUsers: [],
            errors: {},
            briefings: [],
        });
    }

    loadPurchases = searchTerm => {
        let data = {
            org_only: 1,
            search: searchTerm
        };
        return apiRequest("/purchases", {data, method: 'GET'})
            .then(response => {
                return response.data.data.map(purchase => (
                    {value: purchase, label: this.formatPurchase(purchase)}
                ));
            });
    }

    formatPurchase(purchase) {
        let organization = purchase.organization ? purchase.organization.name : '';
        let product = purchase.product.name;
        return `${organization} - ${product}`;
    }

    async loadUsers(organizationId) {
        if (!organizationId) {
            organizationId = this.state.organizationId;
        }
        if (!organizationId) {
            throw new Error("Invalid organization id");
        }
        let data = {
            organization_id: this.state.organizationId
        };
        return await apiRequest("/users", {data, method: 'GET'})
            .then(response => this.setState({users: response.data.data}));
    }

    loadEnabledUsers(purchaseId, shouldLoadUsers) {
        this.setState({loading: true});

        let usersPromise;
        if (shouldLoadUsers) {
            usersPromise = this.loadUsers(this.state.organizationId);
        } else {
            usersPromise = Promise.resolve(true);
        }

        let purchasesByUser = [];

        return usersPromise.then(() =>
            apiRequest(`/purchases/${purchaseId}/enabled-users`, {method: "GET"}).then(response => {

                response.data.data.map(purchase => {
                    let purchaseUser = purchasesByUser.find(find => find.id === purchase.user.id);
                    if (!purchaseUser) {
                        purchaseUser = {
                            id: purchase.user.id,
                            total: 0,
                        };
                    }
                    purchaseUser.total = purchaseUser.total + 1;
                    purchasesByUser.push(purchaseUser)
                });


                this.setState({
                    purchasesByUsers: purchasesByUser,
                    // enabledUsers: response.data.data.map(pu => pu.user.id),
                    loading: false
                });
            })
        );
    }

    hasLinkedProduct(userId) {
        let user = this.state.purchasesByUsers.find(user => user.id === userId);
        return user ? user.total : '0';
    }

    hasUser(userId) {
        return this.state.enabledUsers.indexOf(userId) >= 0;
    }

    hasService(service) {
        return this.state.purchaseUser.indexOf(service) >= 0;
    }

    handlePurchaseChange = purchaseOption => {
        let {value: purchase} = purchaseOption;
        let changeOrganization = purchase.organization.id !== this.state.organizationId;
        let newState = {
            purchase: purchaseOption,
            organizationId: purchase.organization.id,
            briefings: []
        };

        this.setState(newState, () => {
            this.loadEnabledUsers(purchase.id, changeOrganization);
        });
    }

    handleCheck = event => {
        let userId = parseInt(event.target.value);
        let {enabledUsers} = this.state;

        let foundIdx = enabledUsers.indexOf(userId);
        if (foundIdx === -1) {
            enabledUsers.push(userId);
        } else {
            enabledUsers.splice(foundIdx, 1);
        }

        this.setState({enabledUsers: [...enabledUsers]});
    }

    handleCheckServices = event => {

        let purchaseUserId = parseInt(event.target.value);
        let {purchaseUser} = this.state;

        let foundIdx = purchaseUser.indexOf(purchaseUserId);
        if (foundIdx === -1) {
            purchaseUser.push(purchaseUserId);
        } else {
            purchaseUser.splice(foundIdx, 1);
        }

        this.setState({purchaseUser: [...purchaseUser]});
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let {purchase, enabledUsers} = this.state;
        if (!purchase) {
            console.warn('Missing purchase id');
            return;
        }

        let data = {
            user_ids: enabledUsers,
            briefings: this.state.briefings
        };

        this.setState({working: true});

        let request = apiRequest(`/purchases/${purchase.value.id}/enabled-users`, {data, method: 'POST'});
        request.then(response => {
            this.clearForm();
            this.props.notify(
                {
                    type: 'success',
                    message: window.gettext('Usuários associados com sucesso')
                });
        })
            .catch(err => {
                if (err.data.error === 'services_in_use') {

                    let users = err.data.users;
                    let names = users.map(u => u.name);

                    // restore users that cannot be unassigned
                    let enabledUsers = this.state.enabledUsers;
                    users.forEach(u => enabledUsers.push(u.id));
                    // this.setState({enabledUsers: [...enabledUsers]});

                    this.props.notify({
                        type: 'warning',
                        message: window.gettext('Os seguintes usuários já utilizam o serviço: %1 ', names.join(', '))
                    });
                } else if (err.data.error) {
                    this.props.notify({
                        type: 'error',
                        message: err.data.error
                    });
                } else {
                    this.setState({errors: err.data.errors});
                }
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    getUsers = async () => {

        let data = {};
        if (this.state.userData.role !== 'super_admin') {
            data = {
                organization_id: this.state.userData.organization.id
            };
        }
        let users = [];
        await apiRequest("/users", {data, method: 'GET'})
            .then(response => {
                response.data.data.map(user => {
                    users.push({
                       value: user.id,
                       label: user.name,
                    });
                });
            });
        this.setState({usersDesassociar: users});
    }

    async changeUser(user) {

        this.setState({"user": user, 'loading' : true});

        let purchasesIds = [];
        await apiRequest(`/users/${user.value}/purchases`, {method: 'GET'})
            .then(response => {
                let purchases = response.data.purchases.map(purchase => {
                   purchasesIds.push(purchase.id);
                   return purchase;
                });
                this.setState({
                    allPurchasesUser: purchases,
                    purchaseUser: purchasesIds,
                    'loading' : false,
                });
            });
    }

    desassociarServicos = async () => {

        let purchaseUser = this.state.purchaseUser;

        let purchaseToRemove = [];
        this.state.allPurchasesUser.map(
            purchase => {
                let foundPurchase = purchaseUser.indexOf(purchase.id);
                if (foundPurchase === -1) {
                    purchaseToRemove.push(purchase.id);
                }
            }
        );

        if (purchaseToRemove.length === 0) {
            return;
        }

        await apiRequest(`/purchases/user/${this.state.user.value}/remove`,
            {
                method: "POST",
                data: {
                    purchase_user_ids: purchaseToRemove
                }
            }
            ).then(response => {
                this.changeUser(this.state.user);
                if (response.data.purchase_in_use.length > 0) {
                    this.props.notify({
                        type: 'warning',
                        message: window.gettext('Um ou mais produtos não foram desassociados por já estarem em uso.')
                    });
                }
        });
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Form action="" className="form" method="POST"
                                  onSubmit={this.handleSubmit}
                            >
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                          {window.gettext('Associar produtos a usuários')}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <div className="nav-tabs-navigation">
                                        <div className="nav-tabs-wrapper">
                                            <Nav id="tabs" role="tablist" tabs>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={this.state.horizontalTabs === "associar"}
                                                        data-toggle="tab"
                                                        href="#associar"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "associar"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() => {
                                                            this.clearForm();
                                                            this.setState({
                                                                horizontalTabs: "associar",
                                                                user: null,
                                                                usersDesassociar: [],
                                                                allPurchasesUser: []
                                                            })
                                                        }}
                                                    >
                                                        Associar
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        aria-expanded={
                                                            this.state.horizontalTabs === "desassociar"
                                                        }
                                                        data-toggle="tab"
                                                        href="#desassociar"
                                                        role="tab"
                                                        className={
                                                            this.state.horizontalTabs === "desassociar"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() => {
                                                            this.getUsers();
                                                            this.setState({horizontalTabs: "desassociar"})
                                                        }}
                                                    >
                                                        Desassociar
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </div>
                                    <TabContent
                                        id="associar-desassociar-servicos"
                                        activeTab={this.state.horizontalTabs}
                                    >
                                        <TabPane tabId="associar" role="tabpanel">
                                            <Row>
                                                <Label sm="2">Compra</Label>
                                                <Col sm="10">
                                                    <FormGroup>
                                                        <AsyncSelect
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="purchase"
                                                            value={this.state.purchase}
                                                            onChange={this.handlePurchaseChange}
                                                            defaultOptions
                                                            loadOptions={this.loadPurchases}
                                                            placeholder={window.gettext("Selecione uma compra")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Label sm="2">
                                                    {window.gettext('Usuários habilitados')}
                                                </Label>
                                                <Col className="checkbox-radios" sm="10">
                                                    {this.state.loading && (
                                                        <span>
                                                            {window.gettext('Carregando usuários...')}
                                                        </span>
                                                    )}
                                                    {!this.state.loading && this.state.users.map(user => (
                                                        <FormGroup check key={user.id}>
                                                            <Label check className="align-content-center">
                                                                <Input type="checkbox" checked={this.hasUser(user.id)}
                                                                       onChange={this.handleCheck} value={user.id}
                                                                />
                                                                <span className="form-check-sign"/>
                                                                {user.name}
                                                                &nbsp;
                                                                ( {this.hasLinkedProduct(user.id)} )
                                                            </Label>
                                                            <hr/>
                                                        </FormGroup>
                                                    ))}
                                                </Col>
                                            </Row>
                                            <CardFooter>
                                                <Row>
                                                    <Col md="2"/>
                                                    <Col md="10">
                                                        <Button className="btn" color="primary" type="submit"
                                                                disabled={this.state.working}
                                                        >
                                                            {window.gettext('Salvar')}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </TabPane>
                                        <TabPane tabId="desassociar" role="tabpanel">
                                            <Row>
                                                <Label sm="2">{window.gettext("Usuários")} </Label>
                                                <Col sm="10">
                                                    <Select
                                                        className="react-select info"
                                                        classNamePrefix="react-select"
                                                        name="user"
                                                        placeholder={window.gettext("Selecione um usuário")}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.user}
                                                        onChange={value => {
                                                            this.changeUser(value);
                                                        }}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Selecione...",
                                                                isDisabled: true
                                                            },
                                                            ...this.state.usersDesassociar
                                                        ]}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">
                                                    {window.gettext('Produtos habilitados')}
                                                </Label>
                                                <Col className="checkbox-radios" sm="10">
                                                    {this.state.loading && (
                                                        <span>
                                                            {window.gettext('Carregando produtos...')}
                                                        </span>
                                                    )}
                                                    <br />
                                                    {this.state.allPurchasesUser.length === 0 ? window.gettext('Sem produtos vinculados.') : '' }
                                                    {!this.state.loading && this.state.allPurchasesUser.map((purchase) => (
                                                        <>
                                                            <FormGroup check key={purchase.id}>
                                                                <Label check>
                                                                    <Input type="checkbox" checked={this.hasService(purchase.id)}
                                                                           onChange={this.handleCheckServices} value={purchase.id}
                                                                    />
                                                                    <span className="form-check-sign"/>
                                                                    {purchase.product_name}
                                                                </Label>
                                                            </FormGroup>
                                                            <hr />
                                                        </>
                                                    ))}
                                                </Col>
                                            </Row>
                                            <CardFooter>
                                                <Row>
                                                    <Col md="2"/>
                                                    <Col md="10">
                                                        <Button className="btn" color="primary" type="button"
                                                                onClick={this.desassociarServicos}
                                                                disabled={this.state.working}
                                                        >
                                                            {window.gettext('Salvar')}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </TabPane>
                                    </TabContent>
                                    </CardBody>

                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withNotify(PurchaseUsers);
