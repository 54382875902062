import React from "react";

// reactstrap components
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import {Button, Col, Input, Label, Row, Table} from "reactstrap";
import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

class SpecialistBriefingFiles extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      current_user: getUserData(),
      files: [],
      selectedFiles: [],
      confirmModal: false,
      currentFileDelete: null,
    }
  }

  componentDidMount() {
    this.getFiles()
  }


  async getFiles() {
    if (!this.props.purchaseUserId) {
      return
    }
    const files = await apiRequest(`/specialist-briefings/${this.props.purchaseUserId}/files`, {method: 'GET'})
    this.setState({files: files.data.data})
  }

  uploadFile = async () => {
    if (!this.state.selectedFiles) {
      return
    }
    const formData = new FormData();
    for (let i = 0; i < this.state.selectedFiles.length; i++) {
      formData.append('files[]', this.state.selectedFiles[i]);
    }
    await apiRequest(`/specialist-briefings/${this.props.purchaseUserId}/files`, {data: formData, method: 'POST'})
    await this.getFiles()
  }

  downloadFile = (file) => {
    apiRequest(`/specialist-briefings/${this.props.purchaseUserId}/files/${file.uuid}`, {method: "GET"}, {blob: true})
      .then(response => {
        let fileResponse = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        a.href = fileResponse;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  handleInput = event => {
    this.setState({
      selectedFiles: event.target.files,
    });
  };

  confirmDelete(fileInfo) {
    this.setState({confirmModal: true, currentFileDelete: fileInfo})
  }

  async delete() {
    await apiRequest('/specialist-briefings/' + this.props.purchaseUserId + '/files/' + this.state.currentFileDelete.uuid, {method: 'DELETE'})
    await this.getFiles()
  }

  render() {
    return (
      <>
        <ConfirmModal message={window.gettext('Deseja realmente excluir este arquivo?')}
                      onConfirm={() => {this.delete()}}
                      onToggle={() => {this.setState({confirmModal: false})}}
                      open={this.state.confirmModal}
        />
        {this.renderForm()}
        {this.renderTable()}
      </>
    );
  }

  renderForm() {
    if (this.props.disabledForm) {
      return <></>
    }

    return <>
      <Row className={'align-items-center'}>
        <Col md={8}>
          <Label for="fileUpload">
            { window.gettext('Arquivo') }
          </Label>
          <Input
            id="fileUpload"
            name="file"
            type="file"
            onInput={this.handleInput}
            multiple
          />
        </Col>
        <Col md={2}>
          <Button color="primary" size="sm" onClick={this.uploadFile}>
            <span className="ml-1 btn-label">
              {window.gettext('Enviar')}
            </span>
          </Button>
        </Col>
      </Row>
      <hr/>
    </>
  }

  renderTable() {
    return <>
      <Table>
        <thead>
        <tr>
          <th>{window.gettext('Nome do Arquivo')}</th>
          <th>{window.gettext('Ação')}</th>
        </tr>
        </thead>
        <tbody>
        {this.state.files.map((info, index) => {
          return this.renderRow(info, index)
        })}
        </tbody>
      </Table>
    </>
  }

  renderRow(info, index) {
    return <>
      <tr key={index}>
        <td>{info.name}</td>
        <td>
          <Button color="primary" size="sm" onClick={() => this.downloadFile(info)}>
            <span className="ml-1 btn-label">
              <i className="nc-icon nc-cloud-download-93"/>
            </span>
          </Button>
          {this.renderDeleteButton(info)}
        </td>
      </tr>
    </>
  }

  renderDeleteButton(info) {
    if (this.props.disabledForm) {
      return;
    }
    return <>

      <Button color="primary" size="sm" onClick={() => this.confirmDelete(info)}>
        <span className="ml-1 btn-label">
          <i className="nc-icon nc-simple-remove"/>
        </span>
      </Button>
    </>
  }
}

export default withNotify(SpecialistBriefingFiles);
