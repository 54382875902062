import React from "react";

// reactstrap components
import {
    Alert,
    Button,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink, TabContent, TabPane,
} from "reactstrap";
import withNotify from "../../../lib/NotificationWrapper";
import {getUserData} from "../../../lib/Auth";
import apiRequest from "../../../lib/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import SpecialistBriefingFiles from "./SpecialistBriefingFiles";

class SpecialistBriefingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_user: getUserData(),
            isOpen: false,
            textBriefing: '',
            confirmModal: false,
            currentBriefing: null,
            horizontalTabs: 'description'
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isOpen === false && this.props.open) {
            this.setState({isOpen: true})
            this.getBriefing()
        }
    }

    async getBriefing() {
        this.setState({textBriefing: ''})
        const response = await apiRequest('/specialist-briefings?purchase_user_id=' + this.props.purchaseUserId, {method: 'GET'})
        if (response.data.data) {
            this.setState({textBriefing: response.data.data.briefing, currentBriefing: response.data.data})
        }
    }
    cancel() {
        this.setState({isOpen: false, textBriefing: '', horizontalTabs: 'description'});
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    async save() {

        const data = {
            text_briefing: this.state.textBriefing,
            purchase_user_id: this.props.purchaseUserId,
        }
        await apiRequest(`/specialist-briefings`, {data})
        this.props.notify(
          {
              type: 'success',
              message: window.gettext('Briefing salvo com sucesso.')
          });
        this.setState({textBriefing: ''}, () => this.cancel() )
    }

    handleChange = (event) => {
        const {name} = event.target
        this.setState({[name]: event.target.value})
    }

    confirmDelete() {
        this.setState({confirmModal: true})
    }

    async delete() {
        await apiRequest(`/specialist-briefings/${this.state.currentBriefing.uuid}`, {method: 'DELETE'})
        this.props.notify({
            type: 'success',
            message: window.gettext('O briefing foi excluído com sucesso.')
        });
        this.setState({confirmModal: false, textBriefing: ''}, () => this.cancel() )
    }



    render() {
        return (
            <>
                <ConfirmModal message="Deseja realmente excluir este briefing?"
                              onConfirm={() => {this.delete()}}
                              onToggle={() => {this.setState({confirmModal: false})}}
                              open={this.state.confirmModal}
                />
                <Modal
                  size='lg'
                  isOpen={this.props.open}
                >
                    <ModalHeader>
                        {window.gettext('Briefing para Especialistas')}
                    </ModalHeader>
                    <div className="nav-tabs-navigation text-left">
                        <Nav id="tabs" role="tablist" tabs>
                            <NavItem>
                                <NavLink
                                  aria-expanded={this.state.horizontalTabs === "description"}
                                  data-toggle="tab"
                                  href="#description"
                                  role="tab"
                                  className={this.state.horizontalTabs === "description" ? "active" : "" }
                                  onClick={() => this.setState({horizontalTabs: "description"})}
                                >
                                    {window.gettext('Descrição')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                  aria-expanded={this.state.horizontalTabs === "files"}
                                  data-toggle="tab"
                                  href="#files"
                                  role="tab"
                                  className={this.state.horizontalTabs === "files" ? "active" : "" }
                                  onClick={() => this.setState({horizontalTabs: "files"})}
                                >
                                    {window.gettext('Arquivos')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <ModalBody>
                            <TabContent
                              id="description"
                              activeTab={this.state.horizontalTabs}
                            >
                                <TabPane tabId="description" role="description">
                                    <Label for='textBriefing'>Briefing</Label>
                                    <Input type="textarea"
                                           disabled={this.props.disabledForm || false}
                                           name="textBriefing"
                                           value={this.state.textBriefing}
                                           onChange={this.handleChange}
                                           placeholder={window.gettext("Digite o briefing para o usuário selecionado.")}
                                           style={{height: '300px', maxHeight: '300px'}}
                                    />
                                </TabPane>
                            </TabContent>
                            <TabContent
                              id="files"
                              activeTab={this.state.horizontalTabs}
                            >
                                <TabPane tabId="files" role="files">
                                    <SpecialistBriefingFiles purchaseUserId={this.props.purchaseUserId} disabledForm={this.props.disabledForm} />
                                </TabPane>
                            </TabContent>
                        </ModalBody>
                    </div>
                    <ModalFooter>
                        <Button onClick={() => this.cancel()}>
                            {window.gettext('Fechar')}
                        </Button>
                        &nbsp;
                        {this.renderSaveButton()}
                    </ModalFooter>
                </Modal>
            </>
    );
    }
    renderSaveButton() {
        if (this.props.disabledForm) {
            return <></>;
        }

        return (
          <>
              <Button
                color='outline-danger'
                onClick={() => this.confirmDelete()}>
                  {window.gettext('Excluir')}
              </Button>{'  '}
              <Button
                color='primary'
                onClick={() => this.save()}>
                  {window.gettext('Salvar')}
              </Button>{'  '}
          </>
        );
    }
    }

    export default withNotify(SpecialistBriefingModal);
